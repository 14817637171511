<template>
  <CModal id="SendEmail" class="ActionModel" :show.sync="Toggle" :centered="true" size="xl" :closeOnBackdrop="false"
          @update:show="HandleHideModal">
    <template>
      <CInput :label="'收件人'" :value="Receiver" horizontal disabled />
      <CInput :label="'主旨'" v-model="Title" horizontal />
      <CSelect label="郵件模板" horizontal :options="TemplateList" v-model="Template" :value.sync="Template" :placeholder="$t('Global.PleaseSelect')" />
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          郵件內容
        </CCol>
        <CCol sm="9">
          <Editor api-key="4q91812x421qc6po6evarups2m6zj0hj92uulv0nvikgl784" :init="TinyMCE" v-model="Content" />
        </CCol>
      </CRow>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Organization.SendEmail') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="Send()" color="success" :disabled="CurrentMemberList.length <= 0">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: "SendEmail",
  props: ['Toggle', 'MemberList', 'ChooseUsers', 'MessageType'],
  components: {
    Editor
  },
  data() {
    return {
      TinyMCE: TinyMCESetting,
      Submit: false,
      Loading: false,
      Title: '',
      Content: '',
      Template: 'Default',
      TemplateList: [],
      CurrentMemberList: [],
      MessageTypeOptions: {
        Uncheckout: {
          Title: '溫馨提醒：您的購物車有未結帳的商品',
          Content: `<p>您的購物車內仍有未結帳的商品，提醒您記得前往結帳。</p><p>結帳連結<a href="https://${this.$store.state.user.permission.Domain}/cart/checkout" target="_blank">請點我</a>:<br>https://${this.$store.state.user.permission.Domain}/cart/checkout</p>`
        }
      },
    }
  },
  computed: {
    Receiver () {
      if (this.CurrentMemberList.length === 1) {
        return `${this.CurrentMemberList[0].Name}(${this.CurrentMemberList[0].Email})`
      } else if (this.CurrentMemberList.length === this.MemberList.length) {
        return '發送給目前篩選條件下所有會員'
      } else {
        return '發送給所選' + this.CurrentMemberList.length + '位會員'
      }
    }
  },
  mounted() {
    if (this.ChooseUsers.length > 0) {
      this.CurrentMemberList = this.MemberList.filter(item => this.ChooseUsers.includes(item.Uid))
    } else {
      this.CurrentMemberList = this.MemberList
    }
    this.Title = ''
    this.Content = ''
    if (this.MessageType) {
      this.Title = this.MessageTypeOptions[this.MessageType].Title
      this.Content = this.MessageTypeOptions[this.MessageType].Content
    }
    this.GetTemplateList()
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:MessageType', null)
      this.$emit('update:ChooseUsers', [])
    },
    GetTemplateList () {
      const Data = {
        Taxonomy: 'EmailTemplate',
        Page: 1,
        PerPage: 100
      }
      this.TemplateList = []
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/list',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.TemplateList = (data.Data || []).map(item => {
          return {
            label: item.Name,
            value: item.Slug
          }
        })
        if (!this.TemplateList.map(item => item.value).includes('Default')) {
          this.TemplateList.push({
            label: '預設模板',
            value: 'Default'
          })
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Send() {
      this.Loading = true
      this.$Progress.start()
      const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return this.$store.dispatch('InnerRequest', {
        url: '/emailNotify/bulk/send',
        method: 'post',
        data: {
          Title: this.Title,
          Template: this.Template,
          CustomContent: {
            Title: this.Title,
            Content: this.Content
          },
          // 正規表示通過才寄email
          Recipient: this.CurrentMemberList.filter(item => EmailRegex.test(item.Email)).map(item => {
            return {
              Email: item.Email,
              Name: item.Name,
              Phone: item.Phone
            }
          })
        }
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Message/BulkSendEmailSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Message/BulkSendEmailFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
